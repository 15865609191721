// $platform-name: 'clientname';
 

//
// Environment
//

//
// Config values
//
$platform-name: "moorditchgurlonggaassocinc";
$assets-path: "../assets/";

//
// Colours
//
$brand-primary: #C31D1D;
$brand-secondary: #E35F02;
$blue: #619DCC;
$grey: #58595b;
$green: #3D5E0F;
$donate-colour: $brand-primary;
$bg: #ece9e9;
$grey-dark:   #343434;
// Create greyscale

$grey-lightest: #F0F0F0;
// Utility colours

//
// Layout
//
$border-radius: 3px;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1100px,
  xl: 1240px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;
$site-bleed: 20px;
$container-max-width: 1230px;
//
// Misc
//
$body-colour: $grey;
//
// Transitions
//

//
// Logo
//
$logo: "logo.svg";
$logo-width: 225px; // 140px;
$logo-height: 120px; // 140px;
$logo-absolute: true;
//
// Typography
//
$font-open-sans: "Open Sans", sans-serif;
$font-family-base: $font-open-sans;
$headings-font-family: 'Maven Pro', sans-serif;
$headings-colour: #58595b;
$font-weight-bold: 900;

$font-size-h1: 2.5em;
$font-size-h2: 2em;
$font-size-h3: 1.75em;
$font-size-h4: 1.5em;
$font-size-h5: 1.25em;
$font-size-h6: 1em;

// Links

// Icon font

//
// Buttons
//
$btn-padding-x: 1.5em;
$btn-padding-y: .6em;
$btn-font-weight: $font-weight-bold;
$btn-background-colour: $brand-secondary;
$btn-text-colour:white;
$btn-font-family: $headings-font-family;

// Donate button

//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//
// $social-icons-border-radius: 100px;
// $social-icons-width: 30px;
// $social-icons-height: 30px;
// $social-icons-font-size: 1rem;

$social-icons-background-colour: transparent;
$social-icons-colour: #fff;
$social-icons-hover-colour: brand;
// Social network colours - use these by changing $social-icons-use-brand-colours to true

// Header specific

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
//
// Share this page
//
$share-text-align: center; 
//
// Form elements
//
$input-padding-y: .375rem;
$input-padding-x: .75rem;
// Field groups

//
// Tables
//

//
// Card
//
$card-border-radius: $border-radius;
$card-heading-colour: #58595b;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;
$card-hover-details-background-colour: $brand-primary;
$card-hover-heading-colour: white;
$card-border: 0;
$card-heading-font-size: 1.5rem;
// Card text overlay

// Card text over

// Card side by side

// Card hover state

// Event cards

//
// Menu admin
//
$menu-admin-enabled: false;
//
// Header
//
$header-absolute: true;
$header-absolute-background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.35) 67.22%, rgba(0, 0, 0, 0.27) 78.1%, rgba(0, 0, 0, 0) 100%); // Colour or gradient

// Header content
$header-content-margin-top: $spacer / 2;
$header-content-margin-bottom: $spacer / 2;


// Tagline
$tagline-enabled: false;
// Main call to action
$cta-btn-gap: 20px;
$cta-btn-colour: white;
// Search
$header-search-enabled: false;
$nav-search-enabled: true;

// Search - input
$header-search-input-margin-right: -43px;
$header-search-input-padding: $input-padding-y 43px $input-padding-y $input-padding-x;


// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: $grey;
// Search when in nav

// Social icons

//
// Sticky header
//

//
// Navigation
//
$nav-text-transform: uppercase;
$nav-background-colour: transparent;
// Top level items
$nav-top-level-item-font-weight: normal;
$nav-top-level-item-hover-colour: $brand-primary;
$nav-top-level-item-hover-background-colour: rgba(white, 0.8);
$nav-top-level-item-colour: white;
// Submenus
$nav-submenu-width: 250px;
$nav-submenu-background-colour: rgba(white, 0.8);
$nav-submenu-box-shadow: 0 20px 20px rgba(black, 0.05);
$nav-submenu-item-font-size: .875em;
$nav-submenu-item-colour: $grey-dark;
$nav-submenu-item-hover-colour: $brand-primary;
// Burger button

// Nav normal
$nav-normal-margin-bottom: $spacer * 2;
$nav-normal-align-items: center; // left, center, right
//
// Specifically when no banner present and you have gone for $header-absolute: true
//
$no-banner-header-background-colour: transparent;
$no-banner-logo: $logo;
$no-banner-social-icons-header-colour: white; // A colour, or 'brand'
$no-banner-nav-top-level-item-colour: white;
//
// Carousel
//
$carousel-max-width: 100%;
$carousel-details-background-colour: transparent;
$carousel-details-position-x: center; // left, center, right
$carousel-details-max-width: 600px;
$carousel-details-margin-y: $spacer;
$carousel-details-text-align: center;
$carousel-heading-colour: white;
$carousel-summary-colour: white;
$carousel-image-overlay: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7) 100%); // Add a colour value or gradient here
$carousel-details-margin-y: 2rem;
// Carousel controls (left-right buttons)
$carousel-controls-position-x: split; // split, left or right
$carousel-controls-button-background-colour: transparent;
$carousel-controls-position-y: center; // top, center, bottom
$carousel-controls-icon-colour: white;
// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;
// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
// $home-intro-background-colour: white;
// $home-intro-font-size: 1rem;
// $home-intro-padding-y: $spacer * 1;
// $home-intro-margin-top: 4rem;

$home-intro-margin-top: 0;
$home-intro-background-colour: white;
$home-intro-font-size: 1.5em;
$home-intro-font-weight: normal;
$home-intro-max-width: 70%;
//
// Home features
//
$home-features-padding-top: $spacer * 1;
$home-features-padding-bottom: $spacer * 3;
//
// Impact stats
//
$impact-stats-background-image: "impact_shapes_bg.jpg";
$impact-stats-background-image-opacity: 0.5;
// Heading

// Individual stat

// Figure
$impact-stats-figure-font-family: $font-open-sans;
// Summary

//
// Home feeds
//
$home-feeds-background-colour: $bg;
// Feeds title

// Feed item

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;
// Twitter feed

//
// Footer
//
$footer-boxes-count: 4;
$footer-prefab: 5;
$footer-padding-y: $spacer * 3;
// $footer-background-colour: rgba(#eceeef, .3);
$footer-background-colour: $brand-primary;
$footer-colour: white;
$footer-link-colour: white;
$footer-headings-font-size: 1.5em;
// Footer admin links
// Newsletter
$newsletter-background-colour: $brand-primary;
$newsletter-button-background-colour: $brand-secondary;
$newsletter-button-colour: $white;
$newsletter-heading-text-align: left;
$newsletter-heading-font-size: 1.5em;
$newsletter-use-placeholders: false;
$newsletter-colour: white;

// Newsletter hero - full width form above rest of footer

//
// Context container
//

// Donors list

//
// Posts
//

//
// Header text (container for breadcrumb and page title)
//

//
// Page title
//

//
// Sidebar
//

// Associated lists

//
// Listing
//

// Listed post settings

//
// Breadcrumb
//

//
// Blockquote
//

//
// Fundraising
//

// Find a fundraiser widget

// Top fundraisers

//
// Donation form
//

// Donation amounts
$donation-amount-figure-font-size: $font-size-h2;
//
// Quick giving panel
//

// Home quick giving panel
$home-quick-giving-enabled: true;
$home-quick-giving-type: simple;
$home-quick-giving-max-width: 100%;
$home-quick-giving-content-max-width: $container-max-width;
$home-quick-giving-margin-top: 0;
$home-quick-giving-margin-bottom: 0;
$home-quick-giving-padding-y: $spacer * 4;
$home-quick-giving-background-colour: $white;
$home-quick-giving-donation-amount-background-colour: $grey-lightest;
$home-quick-giving-donation-amount-figure-colour: $body-colour;
$home-quick-giving-donation-amount-border-width: 0;
$home-quick-giving-donation-amount-border-colour: transparent;
$home-quick-giving-donation-amount-selected-background-colour: $grey-lightest;
$home-quick-giving-donation-amount-selected-figure-colour: $brand-secondary;
$home-quick-giving-donate-btn-background-colour: $brand-secondary;
$home-quick-giving-donate-btn-colour: $white;
$home-quick-giving-donate-btn-border-colour: $brand-secondary;
$home-quick-giving-donate-btn-font-size: $donation-amount-figure-font-size * 0.75;
$home-quick-giving-colour: $body-colour;
$home-quick-giving-heading-colour: $headings-colour;
$home-quick-giving-heading-font-size: $font-size-h2;

// Adjacent homepage quick giving

// Overlay homepage quick giving

//
// Totalisers
//

//
// Comments
//

//
// Blog details
//

//
// FAQs
//

//
// Shop
//

// Departments list

// Product-price

// Product post

//
// Subsite
//

// Subsite logo

// Subsite nav

//
// Cookie consent
//

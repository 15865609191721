// Add a little text shadow for menu
.menuMain {
  .topLevel>li.level1 {
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    letter-spacing: 1px;

    .page-no-banner & {
      text-shadow: none;
    }
  }

  .subMenu li {
    text-transform: none;
    text-shadow: none;
    letter-spacing: 0;
  }
}

//header
.page-no-banner .pageHeader {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.35) 67.22%, rgba(0, 0, 0, 0.27) 78.1%, rgba(0, 0, 0, 0) 100%);
  padding-bottom: 20px;
}

// carousle shape

@media (min-width: $carousel-breakpoint + 1px) {
  .carouselSlide {
    &:after {
      content: "";
      display: block;
      width: 100%;
      position: absolute;
      bottom: -3px;
      left: 0;
      height: 100%;
      background-image: url($assets-path+"carousel_shape.svg");
      background-size: 105% auto;
      background-position: bottom center;
      background-repeat: no-repeat;
      pointer-events: none;
      z-index: 2;
      visibility: visible;
    }
  }
}


//intro

.homeIntro {
  h2 {
    font-size: $font-size-h4;
    text-transform: uppercase;
    margin-bottom: 0.25em;
  }
}


.homefeaturecategory-homeboximpactstats {
  position: relative;

  //   td:first-of-type {
  //      background-image: url(../assets/impact_bg.svg);

  //     background-size: contain;
  //     background-repeat: no-repeat;
  //     padding: 4rem 0;
  //     background-position: 50% center;
  //     margin-bottom: 1rem;
  // }
  // &:before {
  //     content: "";
  //     display: block;
  //     width: 100%;
  //     position: absolute;
  //     top: -3px;
  //     left: 0;
  //     height: 100%;
  //     background-image: url(../assets/impact_top.svg);
  //     background-size: 105% auto;
  //     background-position: top center;
  //     background-repeat: no-repeat;
  //     pointer-events: none;
  //     z-index: 2;
  //     visibility: visible;
  // }
  &:after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    top: -3px;
    left: 0;
    height: 100%;
    background-image: url(../assets/impact_bottom_3.svg);
    background-size: 105% auto;
    background-position: top center;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 2;
    visibility: visible;
    bottom: 0;
  }
}


//homeboxes
.homeBox1 .home-feature-image-link::before,
.homeBox2 .home-feature-image-link::before,
.homeBox3 .home-feature-image-link::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../assets/hb_shape.svg);
  background-size: 101%;
  background-position: top center;
  z-index: 9;
  background-repeat: no-repeat;
}


.homeBox2 .home-feature-image-link::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../assets/hb_shape_mid.svg);
  background-size: 101%;
  background-position: top center;
  z-index: 9;
  background-repeat: no-repeat;
}

//quick giving
.homefeaturecategory-homeboxquickgiving {
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    top: calc(((-45 / 1400) * 100vw) + 1px);
    left: 0;
    height: calc(((45 / 1400) * 100vw) + 1px);
    background-image: url(../assets/impact_top_3.svg);
    background-size: 105% auto;
    background-position: top center;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 2;
    visibility: visible;

  }

}



//footer
.footerBox.footerBox3 {



  p {
    padding: 20px 0;
    font-size: 12px;
  }
}

.footerBox.footerBox4 {
  border-top: 1px solid rgba(white, 0.3);
  font-size: 12px;

  p {
    padding: 20px 20px 0;
  }
}

.footerBox1 {
  padding-right: 6rem;
}

.footerLocations {
  display: flex;
  max-width: $container-max-width;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 1rem $site-bleed;

  .footerBoxLocation {
    p {
      padding: 0px 0 20px;
    }

    position: relative;
    color: $white;
    font-size: 1em;
    display: block;
    width: 30%;
    margin-right: 5%;

    h5 {
      margin-bottom: 1rem;
    }

    h5,
    a {
      color: $white;
    }

    a.cta-button {
      background-color: $brand-secondary;
    }

    a.cta-button:hover {
      background-color: darken($brand-secondary, 10%);
    }

    &:last-of-type {
      margin-right: 0;
    }

    @media (max-width: 768px) {
      width: 100%;
      margin: 0 0 2rem 0;
    }
  }
}

.shareList a:before {
  color: $body-colour;
}


// Mailchimp setup
.footerBox[class*='ewsletter'] #mc_embed_signup_scroll {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  h2 {
    width: 100%;
    font-size: $font-size-h5;
  }

  .indicates-required {
    font-size: .75em;
    position: absolute;
    top: 2.5px;
    right: 15px;
    color: rgba($white, 0.65);
  }

  >.mc-field-group {
    display: flex;
    flex-direction: column;
    margin: 10px 15px 5px 0;
    flex: 1;

    &.eMail,
    &.lName,
    &.fName,
    &.pCode {
      width: calc(50% - 15px);

      @media (max-width: map-get($breakpoints, sm)) {
        width: 100%;
      }
    }

    >label {
      margin: 0 0 5px 0;
      color: rgba($white, 0.65);
      font-size: $font-size-small;
    }

    @media (max-width: map-get($breakpoints, sm)) {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }

  >.mc-field-group.gdpr-mergeRow {
    font-size: $font-size-small;

    p {
      margin-bottom: 0.25em;
    }
  }

  fieldset.gdprRequired {
    display: flex;

    .av-checkbox {
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  .clear:last-of-type {
    width: 100%;
  }

  input.cta-button {
    margin-top: 10px;
    background-color: $brand-secondary;
    color: $white;

    &:hover {
      background-color: darken($brand-secondary, 10%);
    }
  }
}

.footerBox1 {
  padding-right: 0;
}


.homefeaturecategory-homeboxquickgiving .quickGivingPanel .formQuestion.donationAmount.donationSelected {
  box-shadow: inset 0px 0px 0px 2px $brand-secondary;
}

// // Slightly bigger logo when possible
// @media (min-width: 1248px) {
//   .mainLogo {
//     width: 250px;
//     height: 180px;
//   }
// }